@import "./../../styles/variable";
.paginationWrap {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    justify-content: flex-end;
  }
  .prev,
  .next,
  .paginationItem {
    text-align: center;
    border: 2px solid #bbbbbb;
    justify-content: center;
    padding: 4px;
    border-radius: 4px;

    width: 32px;
    height: 32px;
    font-size: 12px;
    margin-bottom: 32px;
    @media (min-width: 768px) {
      width: 40px;
      height: 40px;
      font-size: 16px;
      margin-bottom: 0px;
    }

    &_disabled {
      border: 2px solid lightgray;
      background-color: lightgray;
    }
    &:hover {
      background-color: $primary;
      color: $white;
      border: 2px solid $primary;
      cursor: pointer;
    }
  }
  .paginationList {
    display: flex;
    gap: 8px;
    .paginationItem {
      padding: 5px;
      &_active {
        border: 2px solid $primary;
        background-color: $primary;
        color: $white;
        font-weight: 800;
      }
    }
  }
}
