@import "./variable";
@import "./mixins";

.contentWrap {
  padding: 110px 0px;
  .heading {
    color: #000;
    font-size: 24px;
    font-weight: 700;
  }
  .summary {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 55px;
  }
  .iconBox {
    @include row;
    gap: 8px;
    color: #292d32;
    font-size: 20px;
    font-weight: 700;
    img {
      width: 29px;
      height: 29px;
    }
  }
  hr {
    margin: 39px 0px;
  }

  .groupWrap {
    @include column;
    gap: 28px;
    .options {
      label {
        color: #292d32;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
      }
      select {
        height: 47px;
        font-size: 14px;
        font-weight: 400;
        border-color: #292d32;
      }
    }
  }

  .transport {
    @include column;
    gap: 28px;
    .boxCard {
      @include column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      min-height: 180px;
      padding: 27px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      margin-bottom: 12px;
      img {
        width: 68px;
      }
    }
    .btn {
      width: 100%;
      border-radius: 6px;
      padding: 10px 0px;
      background-color: $second;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }
  }

  .summaryPrice {
    .summaryBox {
      @include column;
      padding: 32px;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      color: #292d32;
      font-size: 16px;
      font-weight: 500;
      .title {
        color: #292d32;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 15px;
      }
      .textWrap {
        @include row;
        justify-content: space-between;
        margin-bottom: 12px;
      }
      .iconBox {
        @include row;
        gap: 4px;
        align-items: center;
        color: $textFadeTwo;
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 4px;
        img {
          width: 18px;
          width: 18px;
        }
      }
      .totalWrap {
        @include row;
        justify-content: space-between;
        margin-top: 50px;
        padding-top: 27px;
        border-top: 1px solid #d9d9d9;
        .heading {
          color: #292d32;
          font-size: 16px;
          font-weight: 500;
        }
        .total {
          color: $primary;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .summaryPrice {
    display: none;
  }
}
