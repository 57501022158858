.searchContainer {
  max-width: 1156px;
  margin: 0 auto;
  position: relative;
  top: -10.5rem;
}

.searchContainerFull {
  max-width: 1156px;
  margin: 0 auto;
  position: relative;
}

.searchContainer .renderForm {
  background-color: #fff;
  padding: 52px 32px 46px;
  border-radius: 42px 24px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.searchContainerFull {
  padding: 8px;
  .renderForm {
    // border-radius: 16px;

    // height: calc(100vh - 1rem);
    // overflow: auto;
    // padding: 32px 24px;
    @media (min-width: 768px) {
      background-color: #fff;
      box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
        rgba(17, 17, 26, 0.05) 0px 8px 32px;
      border-radius: 42px 24px;
      padding: 52px 32px 46px;
      // height: auto;
    }
    .formWrap {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 9rem);
      justify-content: space-between;
      @media (min-width: 768px) {
        height: auto;
      }
    }
  }
}

.dropdownSearch {
  background-color: #fff;
  padding: 32px 32px 42px;
  width: calc(35% - 1rem);
  border-radius: 16px;
  position: relative;
  top: 3rem;
}

.dropdownSearch .resultSearch {
  position: absolute;
  z-index: 2;
  background-color: #fff;
  border-radius: 16px;
  padding: 16px 0;
  width: calc(100% - 4rem);
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  max-height: 250px;
  overflow: auto;
}

.dropdownSearch .resultSearch > .itemSearch {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
}
.dropdownSearch .resultSearch > .itemSearch:last-child {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.dropdownSearch .resultSearch > .itemSearch .iconSearch {
  display: flex;
  align-items: center;
  margin-right: 14px;
}
.dropdownSearch .resultSearch > .itemSearch .textSearch {
  font-weight: 500;
}

.renderForm .buttonSearch {
  color: #fff;
  font-weight: 600;
  line-height: 26px;
  padding: 8px 24px;
  border-radius: 8px;
  background-color: #5889f0;
  border: none;
  font-size: 14px;
  width: 100%;
  @media (min-width: 768px) {
    font-size: 16px;
    padding: 12px 62px;
  }
}

.renderForm .buttonSearchSecondary {
  color: #fff;
  font-weight: 600;
  line-height: 26px;
  padding: 8px 32px;
  border-radius: 8px;
  color: #5889f0;
  border: 1px solid #5889f0;
  font-size: 14px;
  width: 100%;
  margin-top: 16px;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 16px;
    padding: 12px 62px;
  }
}

.buttonWrap {
  width: 100%;
  text-align: end;
}

.dropdownSearch input {
  margin-bottom: 0;
  padding: 12px 24px 12px 58px !important;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.dropdownSearch .wrapFilter {
  position: relative;
}

.dropdownSearch .wrapFilter img {
  position: absolute;
  top: 9px;
  bottom: 9px;
  left: 16px;
}

.dropdownSearch select {
  border-radius: 16px;
  position: relative;
  width: 100% !important;
  padding: 12px 24px;
  background-color: #f3f3f3;
  border: none;
}

.fieldMain {
  position: relative;
  // display: flex;
  // flex-direction: column;
  gap: 16px;
}

.fieldLeft {
  // display: flex;
  // flex-direction: row;
  width: calc(50% - 8px);
  margin-bottom: 16px;
}

.wrapDate {
}

.fieldRight {
  width: calc(50% - 8px);
  margin-bottom: 16px;
}

.travelWrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
  position: relative;
  align-items: center;
}

.typeTravel {
  display: flex;
  gap: 16px;
  margin-bottom: 12px;
}

.wrapCalculator {
  display: flex;
  gap: 8px;
  color: #5889f0;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

.tabTravel {
  border-radius: 8px;
  padding: 6px 22px;
  background-color: #f3f3f3;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
}
.tabTravel.active {
  background-color: #5889f0;
  color: #fff;
}

.cardWrapProduct {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
