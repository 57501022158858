@import "./variable";
@import "./mixins";


.searchWrap {
  display: flex;
  gap: 1rem;
  margin-bottom:2rem;
  padding: 1.5rem;
  border-radius: 1rem;
  background-color: $second;
  color: $white;
  select {
    border: none !important;
  }
  input {
    border: none !important;
  }
}

// .searchWrap {
//   margin: 60px 0px;

//   @media (min-width: 768px) {
//     margin: 80px 0px;
//   }
//   display: flex;
.filterBox {
  padding: 0 0 16px;
  position: sticky;
  top: 6.5rem;
  border: none;
  @media (min-width: 768px) {
    border-radius: 12px;
    padding: 28px 22px;
    border: 1px solid #d9d9d9;
  }
  display: block;
  form {
    .formSearch {
      .fieldMain {
        gap: 1rem;
        .fieldLeft {
          width: calc(50% - 8px);
          input {
            width: 100%;
          }
        }
        .fieldRight {
          width: calc(50% - 8px);
          input {
            width: 100%;
          }
        }
      }
    }
    select {
      font-size: 14px;
      padding: 16px;
      font-weight: 400;
      @media (min-width: 768px) {
        padding: 10px;
      }
    }
    .inputDate {
      padding: 17px;
      margin-bottom: 16px;
    }
    .priceGroup {
      @include row;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      input {
        font-weight: 400;
        padding: 12px 14px;
        font-size: 12px !important;

        @media (min-width: 768px) {
          font-size: 14px !important;
        }
      }
    }
    .starGroup {
      @include row;
      gap: 12px;
      .star {
        @include row;
        gap: 12px;
        font-size: 12px;
        @media (min-width: 768px) {
          font-size: 14px;
        }
        img {
          width: 16px;
        }
      }
      margin-bottom: 8px;
    }
    button {
      display: flex;
      gap: 8px;
      border: none;
      border-radius: 8px;
      background: $second;
      font-size: 12px;
      padding: 12px 14px;
      @media (min-width: 768px) {
        font-size: 14px;
      }
      // box-shadow: 0px 4px 10px 2px rgba(228, 145, 15, 0.2);
    }
    button:hover {
      background: $second;
    }
  }
  .customPaket {
    @include column;
    display: flex;
    gap: 20px;
    div {
      color: #292d32;
      font-size: 16px;
      font-weight: 600;
    }
    .btnCustom {
      border-radius: 12px;
      border: 1px solid #fca418;
      background: #fff;
      box-shadow: 0px 4px 10px 2px rgba(228, 145, 15, 0.2);
      font-size: 14px;
      padding: 19px 24px;
      width: 100%;
      text-align: center;
    }
  }
}
.sortBox {
  @include row;
  justify-content: flex-end;
  margin-bottom: 39px;
  font-size: 16px;

  .addFilter {
    font-weight: 600;
    border-radius: 8px;
    width: 180px;
    border: 1px solid #dee2e6;
    width: auto;
    img {
      margin-right: 8px;
    }
    font-size: 12px;
    padding: 8px 12px;
    @media (min-width: 768px) {
      display: none;
    }
  }
  .addSort {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 21px;
    .iconBox {
      display: none;
    }
    select {
      font-size: 14px;
      font-weight: 400;
      border-radius: 8px;
      width: 180px;
      padding: 16px;
      @media (min-width: 768px) {
        padding: 10px;
      }
    }
  }
}
.listWrap {
  // justify-content: space-between;
  .listItem {
    @include column;
    //   height: 410px;
    height: auto;
    margin-bottom: 16px;
    @media (min-width: 768px) {
      height: auto;
      margin-bottom: 24px;
    }
    &_available {
      border: 2px solid #fca418;
    }
  }
}
// }
.btnLoad {
  display: none;
}

@media (max-width: 767.98px) {
  // .filterBox {
  //   display: none !important;
  // }
  .sortBox {
    justify-content: space-between !important;
    margin-bottom: 23px !important;
    display: flex;
    align-items: center;
    .iconBox {
      display: flex !important;
      gap: 8px;
      color: #292d32;
      font-size: 16px;
      font-weight: 600;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      padding: 10px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    label {
      display: none !important;
    }
    select {
      height: 38px;
      padding: 8px 14px !important;
      font-size: 12px !important;
      font-weight: 600 !important;
    }
  }
  .btnLoad {
    display: block;
    border-radius: 12px;
    border: 1px solid #fca418;
    background: #fff;
    box-shadow: 0px 4px 10px 2px rgba(228, 145, 15, 0.2);
    font-size: 14px;
    padding: 19px 24px;
    width: 100%;
    text-align: center;
    margin-top: 36px;
  }
}
