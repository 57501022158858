@import "./../../styles/variable";
@import "./../../styles/mixins";

.recomendation {
  @include column;
  margin-top: 38px;
  margin-bottom: 104px;
  @media (min-width: 768px) {
    margin-bottom: 72px;
  }
  gap: 60px;
  .heading {
    @include row;
    color: #000;
    font-size: 16px;
    @media (min-width: 768px) {
      font-size: 24px;
    }
    font-weight: 700;
    justify-content: space-between;
    .seeAll {
      color: #00854c;
      font-size: 20px;
      font-weight: 500;
    }
  }
  .recWarp {
    justify-content: space-between;
    width: 100%;
    .recCard {
      @include column;
      height: 410px;
      margin-bottom: 24px;
    }
  }
}

@media (max-width: 767.98px) {
  .recomendation {
    margin-top: 51px;
    gap: 24px;
    .heading {
      .seeAll {
        display: none;
      }
    }
  }
}
