@import "./variable";
@import "./mixins";

.heroShot {
  height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  @media (min-width: 768px) {
    height: 55vh;
  }
  .backBtn {
    display: block;
    @media (min-width: 768px) {
      display: none;
    }
  }
  .overlay {
    // opacity: 0.8999999761581421;
    // background: $primary;
    // mix-blend-mode: multiply;
    // height: 100%;
    // position: absolute;
    // width: 100%;
  }
}
.heroShotDesktop {
  img {
    border-radius: 16px;
    width: 100%;
    height: 420px;
    margin-top: 24px;
    object-fit: cover;
    margin-bottom: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }
}
.detailBox {
  // padding: 10px 0px 22px;
  // color: $white;
  z-index: 1;
  @media (min-width: 768px) {
    max-width: 50%;
    width: 66.66666667%;
    // padding: 32px 0px 68px;
  }

  .detailWrap {
    @include row;
    align-items: center;
    gap: 14px;
    .discount {
      display: none;
      @media (min-width: 768px) {
        display: block;
        width: fit-content;
        height: fit-content;
        font-size: 16px;
        margin-bottom: 0px;
      }
    }
    .regulerPrice {
      font-size: 12px;
      color: #d4d4d4;
      font-weight: 500;
      @media (min-width: 768px) {
        font-size: 16px;
      }
      text-decoration: line-through;
    }
  }
  .salePrice {
    margin-bottom: 2px;
    color: $primary;
    font-size: 16px;
    font-weight: 700;
    @media (min-width: 768px) {
      font-size: 24px;
      margin-bottom: 8px;
    }
  }
}
.iconWrap {
  gap: 6px;
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 16px;
    gap: 8px;
    align-items: center;
  }
  @include row;
  .iconBox {
    @include row;
    font-weight: 700;
    gap: 4px;
    // font-size: 12px;
    align-items: center;
    // @media (min-width: 768px) {
    //   font-size: 24px;
    // }
    img {
      width: 16px;
      height: 16px;
      @media (min-width: 768px) {
        width: 22px;
        height: 22px;
      }
    }
  }
}
.title {
  font-size: 16px;
  font-weight: 600;
  // margin-top: 12px;
  margin-bottom: 0px;
  // color: $white;
  // margin-bottom: 13px;
  position: relative;
  @media (min-width: 768px) {
    font-size: 24px;
  }
}
.descriptionProduct {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
  }
}
.btnWrap {
  @include row;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1;
  // padding: 22px 0px;
  // max-width: 40%;
  gap: 9px;
  @media (min-width: 768px) {
    padding: 20px auto 20px 0;
    gap: 21px;
    // width: 33.33333333%;
    // max-width: 50%;
  }
  .discount {
    display: block;
    height: fit-content;
    @media (min-width: 768px) {
      display: none;
    }
  }
  .purchase {
    display: none;
    cursor: pointer;
    @media (min-width: 768px) {
      max-width: 217px;
      display: block;
    }
  }
  .btnReceipt {
    // display: none !important;
    // @media (min-width: 768px) {
    //   display: block !important;
    // }
  }
  .btnShare {
    cursor: pointer;
    background: $white;
    padding: 10px;
    line-height: 0px;
    border-radius: 6px;
    border: 1px solid $second;
    @media (min-width: 768px) {
      border-radius: 12px;
      background: $white;
      padding: 14px;
      // box-shadow: 0px 4px 10px 2px rgba(228, 145, 15, 0.2);
    }

    img {
      width: 20px;
      height: 20px;
      @media (min-width: 768px) {
        width: 28px;
        height: 28px;
      }
    }
  }
}
.contentWrap {
  margin-top: 24px;
  margin-bottom: 24px;
  @media (min-width: 768px) {
    margin-top: 20px;
    margin-bottom: 36px;
    color: $text;
  }
  .contentBoxOutline {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 20px 24px;
    margin-bottom: 24px;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    .itemBox {
      font-size: 16px;
      font-weight: 700;
      color: #292d32;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .itemBoxSub {
      font-weight: 400;
      color: #8a8a8a;
      font-size: 10px;
      display: flex;
      align-items: flex-end;
    }
    .itemBoxPrice {
      font-weight: 800;
      color: $second;
      font-size: 18px;
    }
  }
  .detail {
    order: 2;
    @media (min-width: 768px) {
      order: 1;
    }
    margin-bottom: 2rem;

    .contentBox {
      gap: 10px;
      margin-bottom: 22px;
      @media (min-width: 768px) {
        @include column;
        gap: 14px;
        margin-bottom: 44px;
      }
      ul {
        padding-left: 16px;
      }
      .heading {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 10px;
        @media (min-width: 768px) {
          font-size: 24px;
          margin-bottom: 0px;
        }
      }
      .flight_seat_item {
        margin-bottom: 12px;
        padding-bottom: 24px;
        border-bottom: 1px solid #d9d9d9;
        &:last-child {
          border-bottom: 0;
        }
      }
      .content {
        display: block;
        font-size: 14px;
        p {
          line-height: 28px;
          text-align: justify;
          margin-bottom: 10px !important;
        }
        @media (min-width: 768px) {
          font-size: 16px;
        }
        .checkList {
          position: relative;
          padding-left: 30px;
          line-height: 36px;
          img {
            position: absolute;
            left: 0px;
            top: 6px;
            width: 18px;
            height: 18px;
            @media (min-width: 768px) {
              width: 22px;
              height: 22px;
            }
          }
        }
        ul {
          padding: 0px;
          columns: 2;
          -webkit-columns: 2;
          -moz-columns: 2;
          li {
            @include row;
            list-style: none;
            padding-bottom: 14px;
            img {
              padding-right: 8px;
            }
          }
        }
      }
      .location {
        @include row;
        width: 75%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block !important;
        font-size: 14px;
        @media (min-width: 768px) {
          font-size: 16px;
        }
        img {
          margin-right: 8px;
        }
      }
      // .travel {
      //   @include row;
      //   gap: 16px;
      //   align-items: center;
      //   .img {
      //     width: 57px;
      //     height: 57px;
      //     border-radius: 50%;
      //     background-color: #d9d9d9;
      //   }
      //   .detailTravel {
      //     @include column;
      //     gap: 4px;
      //     line-height: normal;
      //     .travelName {
      //       font-size: 16px;
      //       font-weight: 600;
      //     }
      //     .travelPaket {
      //       color: rgba(41, 45, 50, 0.8);
      //       font-size: 12px;
      //       font-weight: 400;
      //     }
      //   }
      // }
      .hotel {
        @include column;
        gap: 16px;
        border-radius: 8px;
        border: 1px solid #d9d9d9;
        padding: 24px 16px;
        margin-bottom: 24px;
        @media (min-width: 768px) {
          gap: 25px;
          padding: 33px 23px;
          margin-bottom: 0px;
        }
      }
      .review {
        @include column;
        gap: 10px;
        padding-top: 23px;
        padding-bottom: 20px;
        border-bottom: 1px solid #d9d9d9;
        .name {
          font-size: 16px;
          font-weight: 600;
        }
        .rating {
          @include row;
          gap: 4px;
          img {
            width: 19px;
            height: 19px;
          }
        }
        .comment {
          font-size: 14px;
          font-weight: 400;
          line-height: 32px;
          @media (min-width: 768px) {
            font-size: 16px;
          }
        }
        .pictures {
          @include row;
          gap: 18px;
          .rectangle {
            width: 76px;
            height: 76px;
            background-color: gray;
            border-radius: 8px;
          }
        }
        .loadMore {
          color: #2fa84b;
          font-weight: 700;
          line-height: 32px;
        }
      }
    }
  }

  .titleQuantityOrder {
    @include row;
    gap: 10px;
    font-size: 16px;
    font-weight: 700;
    img {
      width: 22px;
      height: 22px;
    }
  }

  .summary {
    order: 1;
    @media (min-width: 768px) {
      order: 2;
    }
    margin-bottom: 2rem;
    .summaryWrap {
      @include row;
      position: sticky;
      top: 6rem;
      justify-content: space-between;
      // gap: 16px;
      padding: 16px;
      flex-wrap: wrap;
      border-radius: 16px;
      border: 1px solid #d9d9d9;
      @media (min-width: 768px) {
        @include column;
        // gap: 37px;
        padding: 32px 24px;
      }
      .quantityWrap {
        display: none;
        @media (min-width: 768px) {
          display: block;
        }
        .quantityOrder {
          display: none;
          @media (min-width: 768px) {
            @include row;
            align-items: center;
            justify-content: space-between;
            // flex-wrap: nowrap;
            // max-width: 100%;
          }
        }
        .titleQuantityOrder {
          @include row;
          gap: 10px;
          font-size: 16px;
          font-weight: 700;
          img {
            width: 22px;
            height: 22px;
          }
        }
        .totalBox {
          @include row;
          justify-content: space-between;
          align-items: center;
          flex-wrap: nowrap;
          font-size: 16px;
          font-weight: 700;
          margin-top: 16px;
          img {
            width: 22px;
            height: 22px;
            margin-right: 10px;
          }
          .total {
            font-size: 20px;
            font-weight: 700;
            color: $primary;
          }
        }
      }
    }
    .addToCart {
      display: none;
      @media (min-width: 768px) {
        display: flex;
      }
    }
  }
}

.detailQuantityOrder {
  @include row;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  padding-left: 0px;
  justify-content: end;
  @media (min-width: 768px) {
    // padding-left: 33px;
  }
  .btnInput {
    border-radius: 50%;
    background-color: $second !important;
    width: 24px;
    height: 24px;
    color: $white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .qty {
    text-align: center;
    padding: 0px 8px;
    font-size: 16px;
    font-weight: 700;
    width: 40px;
    border: none;
    &__mobile {
      width: 40px;
    }
  }
}

// Global
.iconBox {
  @include column;
  gap: 6px;
  @media (min-width: 768px) {
    gap: 10px;
  }
  .rating {
    font-size: 12px;
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
  .sold {
    font-size: 12px;
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
  .titleIconBox {
    @include row;
    gap: 8px;
    font-size: 14px;
    @media (min-width: 768px) {
      gap: 10px;
      font-size: 16px;
    }
    .circleIconBox {
      width: 42px;
      height: 42px;
      border-radius: 24px;
      background-color: #eaeaea;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
        @media (min-width: 768px) {
          width: 22px;
          height: 22px;
        }
      }
    }
    .lineBox {
      width: 1px;
      background-color: #888888;
    }
    .titleItinerary {
      font-weight: 400;
      line-height: normal;
      color: #888888;
      font-size: 13px;
    }
    .descItinerary {
      font-weight: 700;
    }
  }
  .descItinerary {
    font-size: 14px;
    font-weight: 600;
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
  .detailContent {
    font-size: 14px;
    font-weight: 400;
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
  .detailIconBox {
    font-size: 14px;
    font-weight: 400;
    padding-left: 26px;
    @media (min-width: 768px) {
      font-size: 16px;
      padding-left: 33px;
    }
    .btnInput {
      border: none !important;
      border-radius: 24px;
      width: 50px;
      height: 50px;
      background-color: #2fa84b !important;
      cursor: pointer;
    }
    .inputForm {
      text-align: center;
      width: 60px !important;
    }
    ul {
      padding-left: 16px;
      columns: 3;
      -webkit-columns: 3;
      -moz-columns: 3;
      li {
        line-height: 30px;
        margin-bottom: 14px;
      }
    }
    @media (max-width: 768px) {
      ul {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
      }
    }
  }
}

.discount {
  width: fit-content;
  display: block;
  height: fit-content;
  padding: 4px 12px;
  font-size: 14px;
  background: $white;
  border-radius: 8px;
  color: #ff3c3c;
  font-weight: 700;
  @media (min-width: 768px) {
    padding: 8px 20px;
    font-size: 24px;
    margin-bottom: 8px;
  }
}

.stickyNav {
  display: block;
  &__checkout {
    display: flex;
    gap: 16px;
  }
  position: fixed;
  bottom: 0;
  background-color: $white;
  height: 70px;
  width: 100%;
  padding: 16px;
  z-index: 9;
  box-shadow: 0px -6px 27px 0px rgba(0, 0, 0, 0.1);
  @media (min-width: 768px) {
    display: none;
  }
  .totalSection {
    float: left;

    .text {
      color: #292d32;
      font-size: 12px;
      font-weight: 500;
    }
    .price {
      color: #212121;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
    }
  }
  .checkoutBtn {
    padding: 10px 24px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    background: #00854c;
    float: right;
  }
  .button {
    text-align: center;
    width: 50%;
    padding: 10px 24px;
    border-radius: 8px;
    font-size: 12px;
    @media (min-width: 768px) {
      font-size: 16px;
    }
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;

    &__addCart {
      float: left;
      border: 1px solid $second;
      color: #292d32;
      padding: 10px;
      width: max-content;
      @media (min-width: 768px) {
        padding: 10px 42px;
      }
    }
    &__buyHotel {
      width: 100%;
      border: 1px solid $second;
      padding: 10px;
      color: #fff;
      float: right;
      background-color: $second;
      @media (min-width: 768px) {
        padding: 10px 42px;
      }
    }
    &__buyPackages {
      color: #fff;
      float: right;
      background-color: $second;
    }
    &__checkout {
      width: 100%;
      background-color: $second;
      color: #fff;
    }
  }
  .menuMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    font-size: 12px;
    color: #000;
    img {
      margin-bottom: 2px;
    }
  }
}

.listWrap {
  // justify-content: space-between;
  .listItem {
    @include column;
    //   height: 410px;
    height: auto;
    @media (min-width: 768px) {
      height: auto;
    }
    margin-bottom: 24px;
    &_available {
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      display: flex;
      flex-direction: column;
      @media (min-width: 768px) {
        flex-direction: row;
      }
      align-items: center;
      justify-content: space-between;
      .detailItem {
        display: flex;
        width: 100%;
        @media (min-width: 768px) {
          width: auto;
        }
      }
      .iconRoom {
        text-align: right;
        display: flex;
        width: 100%;
        margin-top: 8px;
        gap: 12px;
        @media (min-width: 768px) {
          justify-content: flex-end;
          gap: 16px;
          margin-top: 12px;
          width: auto;
          text-align: right;
        }
        img {
          width: 16px;
          height: 16px;
          @media (min-width: 768px) {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
