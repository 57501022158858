@mixin row_between() {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@mixin col_item_center() {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@mixin column() {
  display: flex;
  flex-direction: column;
}
@mixin row() {
  display: flex;
  flex-direction: row;
}
@mixin center() {
  display: flex;
  justify-content: center;
  align-items: center;
}
