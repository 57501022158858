@import "./variable";
@import "./mixins";

.heading {
  color: #000;
  font-size: $fourthFont;
  font-weight: $extraBold;
}

.listCartWrap {
  margin-top: 100px;
  h3 {
    margin-bottom: 43px;
  }
  .listCart {
    @include row;
    gap: 1rem;
    padding: 40px 0px;
    border-bottom: 1px solid #d9d9d9;
    .thumbnailWrap {
      width: 111px;
      max-height: 111px;
      border-radius: 8px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
    .detailCart {
      @include column;
      gap: 1rem;
      position: relative;
      width: 100%;
      .title {
        color: #292d32;
        font-size: $fifthFont;
        font-weight: $medium;
      }
      .much {
        @include row;
        gap: 6px;
        margin-top: -14px;
        color: #212121;
        font-size: 10px;
        font-weight: 400;
        img {
          width: 15px;
          height: 15px;
        }
      }
      .priceWrap {
        @include row;
        align-items: center;
        gap: 12px;
        .discount {
          padding: 4px 10px;
          color: #fff;
          font-size: 12px;
          font-weight: $extraBold;
          border-radius: 4px;
          background: $third;
        }
        .regulerPrice {
          color: rgba(33, 33, 33, 0.5);
          font-size: 12px;
          font-weight: 400;
          text-decoration: line-through;
        }
        .salePrice {
          font-size: $fifthFont;
          font-weight: $extraBold;
        }
      }
      .countWrap {
        @include row;
        gap: 1rem;
        color: #212121;
        font-size: $fifthFont;
        font-weight: $extraBold;
        align-items: center;
        .icon {
          width: 24px;
          height: 24px;
        }
      }
      .trash {
        width: 33px;
        height: 33px;
        position: absolute;
        top: 0px;
        right: 0px;
      }
    }
  }
}

.summaryPrice {
  margin-top: 100px;
  .summaryBox {
    @include column;
    padding: 32px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    color: #292d32;
    font-size: $fifthFont;
    font-weight: $medium;
    .title {
      color: #292d32;
      font-size: 20px;
      font-weight: $extraBold;
      margin-bottom: 15px;
    }
    .textWrap {
      @include row;
      justify-content: space-between;
      margin-bottom: 12px;
    }
    .totalWrap {
      @include row;
      justify-content: space-between;
      margin-top: 50px;
      padding: 10px 0px;
      border-top: 1px solid #d9d9d9;
      .heading {
        color: #292d32;
        font-size: 20px;
        font-weight: $extraBold;
      }
    }
    .btn {
      margin-top: 56px;
      background-color: $primary;
    }
  }
}

@media (max-width: 767.98px) {
  .heading {
    font-size: 18px;
  }

  .listCartWrap {
    h3 {
      margin-bottom: 33px;
    }
    .listCart {
      gap: 12px;
      padding: 15px 0px;
      .thumbnailWrap {
        width: 60px;
        max-height: 60px;
      }
      .detailCart {
        @include column;
        gap: 8px;
        .title {
          font-size: 14px;
        }
        .much {
          font-size: 8px;
          margin-top: -6px;
          img {
            width: 8px;
            height: 8px;
          }
        }
        .priceWrap {
          gap: 8px;
          .discount {
            padding: 1px 4px;
            font-size: 8px;
          }
          .regulerPrice {
            font-size: 8px;
          }
          .salePrice {
            font-size: 14px;
          }
        }
        .countWrap {
          gap: 14px;
          font-size: 14px;
          .icon {
            width: 20px;
            height: 20px;
          }
        }
        .trash {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .summaryPrice {
    display: none;
  }
}
