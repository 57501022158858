.thankyou_wrap {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  .thankyou_title {
    font-size: 24px;
    @media (min-width: 768px) {
      font-size: 32px;
    }
    font-weight: 600;
    text-align: center;
    margin-bottom: 8px;
  }
  .thankyou_subtitle {
    margin-bottom: 42px;
    text-align: center;
  }
  .thankyou_subtitle {
  }
  .thankyou_cta {
  }
}
