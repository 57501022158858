@import "./../../styles/variable";
@import "./../../styles/mixins";

.thumbnail {
  height: 250px;
  width: 100%;
  position: relative;
  padding: 14px 12px;
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  @media (min-width: 768px) {
    height: 320px;
    border-radius: 16px;
  }
  .featured {
    position: absolute;
    top: 12px;
    left: 12px;
    background-color: $primary;
    color: $white;
    font-size: 12px;
    font-weight: 700;
    padding: 4px 8px;
    border-radius: 4px;
  }
 
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    margin: -14px -12px 0px -12px;
    border-radius: $mainRadius;
  }
  .discount {
    float: right;
    padding: 6px 12px;
    background-color: $white;
    color: $third;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 4px;
    // padding: 10px 14px;
  }
}

.detail {
  margin-top: 8px;
  .wrapTitle {
    @include row_between;
    // padding-left: 8px;
    .title {
      overflow: hidden;
      margin-bottom: 6px;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: $seventhFont;
      font-weight: 500;
      color: $text;
      max-height: 38px;
      @media (min-width: 768px) {
        margin-bottom: 10px;
        font-size: $fifthFont;
        line-height: normal;
      }
    }
    .count {
      @include row;
      border-radius: 4px;
      background-color: #d9d9d9;
      padding: 4px 8px;
      color: #5f5f5f;
      font-size: $sixthFont;
      font-weight: 700;
    }
  }
  .summary {
    @include row;
    color: $white;
    font-size: 14px;
    font-weight: 700;
    gap: 6px;
    background-color: $second;
    // position: absolute;
    padding: 3px 12px;
    border-radius: 6px;
    right: 12px;
    .rate {
      @include row;
      gap: 8px;
    }
    img {
      position: relative;
      object-fit: contain;
      margin: auto;
      width: 14px;
      z-index: 9;
    }
  }
  .time {
    margin-top: 4px;
    font-size: 9px;
    @media (min-width: 768px) {
      font-size: 12px;
    }
  }
  .startFrom {
    margin-bottom: 4px;
    font-size: 10px;
    color: $primary;
    @media (min-width: 768px) {
      margin-bottom: 0px;
      font-size: 12px;
    }
  }
  .priceFrom {
    color: $primary;
    font-size: 16px;
    @media (min-width: 768px) {
      font-size: 24px;
    }
    line-height: 24px;
    font-weight: 500;
  }
  .selectSeat {
    font-weight: 500;
    font-size: 12px;
    margin-top: 12px;
    @media (min-width: 768px) {
      font-size: 16px;
      width: 100%;
    }
  }
  .originalPrice {
    color: #888;
    font-size: 10px;
    font-weight: 600;
    text-decoration-line: line-through;
    &_start {
      text-decoration-line: none;
    }
  }
  .salePrice {
    // padding: 0px 12px;
    color: $primary;
    font-size: $fifthFont;
    line-height: normal;
    font-weight: 500;
    @media (min-width: 768px) {
      font-size: 20px;
    }
  }
}

.seatItem {
  margin-bottom: 1rem;
  padding: 12px 16px;
  border-radius: 10px;
  background-color: $white;
  border: 1px solid #d9d9d9;
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  .seatName {
    margin-bottom: 2px;
    font-size: 1rem;
    font-weight: 700;
    line-height: normal;
  }
  .maxPassengers {
    font-size: 0.75rem;
  }
  .badge {
    font-size: 12px;
    color: $second;
    font-weight: 600;
    padding: 2px 8px;
    border-radius: 6px;
    background-color: #ccdcff;
  }
  .separator {
    height: 1px;
    background-color: #d9d9d9;
    margin: 16px 0;
  }
  .labelModal {
    font-size: 0.7rem;
  }
  .valueModal {
    margin-bottom: 8px;
    font-weight: 700;
    &_price {
      font-weight: 700;
      color: $primary;
    }
  }
  .qty {
    font-size: 12px;
  }
  button {
    font-size: 12px;
    border-radius: 24px;
    cursor: pointer;
    // padding: 4px 8px;
  }
}
.btnBuy {
  font-size: 14px !important;
  font-weight: 600;
  padding: 8px 16px;
  width: 100%;
  &_outline {
    width: 100%;
    padding: 8px 16px;
    font-size: 14px !important;
    border: 1px solid $second;
    color: $second;
    // width: 50%;
    background-color: $white;
  }
}
