@import "./variable";
@import "./mixins";

.menuSub {
  padding: 10px 24px;
  border-radius: 6px !important;
  border: 1px solid #fca418;
  background-color: #fff;
  color: #fca418;
  font-weight: 600;
  &_active {
    background-color: #fca418;
    color: #fff;
  }
}

.listKloter {
  .listKloterItem {
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    background: #fff;
    margin-bottom: 12px;
    .listKloterItemTitle {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid #d9d9d9;
    }
    .listKloterItemDesc {
      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;
      .listKloterItemInfo {
        font-size: 12px;
        color: #808080;
      }
      .listKloterItemCode {
        font-size: 12px;
        font-weight: 600;
        color: #fca418;
        background-color: #fca5182f;
        padding: 2px 12px;
        border-radius: 16px;
      }
    }
    .listKloterCta {
      margin-bottom: 8px;
      .listKloterCtaBtn {
        background-color: $second;
        width: 100%;
        padding: 8px 2px;
        font-size: 12px;
        border: none;
        border-radius: 6px;
        color: #fff;
        font-weight: 600;
      }
    }
  }
}

.formWizardWrap {
  b {
    font-size: 14px;
    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }
  .collapsibleHeader {
    font-size: 14px;
    font-weight: 500;
    padding: 16px 0;
    cursor: pointer;
    border-bottom: 1px solid #d9d9d9;
    &:last-child {
      border-bottom: none;
    }
  }
  .collapsibleContent {
    margin-top: 16px;
  }
  .formWizard {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .formWizardStep {
    font-size: 14px;
    font-weight: 600;
    background-color: lightgray;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    color: #000;
    &.active {
      color: #fff;
      background-color: $second;
      width: auto;
      padding: 0 24px;
    }
  }
}

.btnSubmit {
  background-color: $second;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: 12px 0;
  border-radius: 6px;
  border: none;
  &:disabled {
    background-color: #d9d9d9;
    color: #fff;
  }
}

.available {
  font-size: 10px;
  background-color: #fca5182f;
  color: #fca418;
  padding: 2px 8px;
  border-radius: 16px;
}

.headerProfileMobile {
  background-color: #161616;
  padding: 20px 16px;
  height: 90px;
  border-radius: 0;
  text-align: left;
  margin-bottom: 24px;
  @media (min-width: 768px) {
    height: 160px;
    padding: 32px 16px;
    margin-bottom: 0;
  }

  .username {
    color: $white;
    font-size: 16px;
    font-weight: $extraBold;
    @media (min-width: 768px) {
      font-size: 24px;
    }
  }
  .subUsername {
    color: $white;
    font-size: 14px;
    font-weight: $reguler;
  }
  .logoutMobile {
    font-size: 12px;
    color: $white;
    padding: 4px 12px;
    border-radius: 16px;
    border: 1px solid $white;
    display: flex;
    align-self: center;
    justify-content: center;
  }
}

.menuTitle {
  margin-bottom: 16px;
  margin-top: 2.5rem;
  color: $text;
  font-size: $fifthFont;
  font-weight: $extraBold;
  @media (min-width: 768px) {
    font-size: 24px;
  }
}

.menuMobile {
  width: 100%;
  // border-radius: 8px;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  // margin-bottom: 12px;
  color: $text;
  font-size: 14px;
  font-weight: $extraBold;
  cursor: pointer;
  padding: 4px 4px 16px;
  &:hover {
    background-color: #b3b3b32f;
  }
  &:last-of-type {
    border-bottom: none;
  }
  @media (min-width: 768px) {
    font-size: $fifthFont;
    padding: 24px 20px;
  }
  .logoutMobile {
    text-align: center;
    width: 100%;
  }
  &:last-child {
    margin-bottom: 50px;
  }
}
.lastMenu {
  background-color: $third;
  color: #fff;
  margin-bottom: 100px;
}
