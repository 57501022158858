// common color
$primary: #00854c;
$second: #5889F0;
$third: #ff3c3c;
$fourth: #ff9a28;
$background: #ff3c3c;
$text: #000000;
$textFade: #888888;
$textFadeTwo: #5f5f5f;
$primaryFade: #ecf7ee;
$white: #fff;

// radius
$mainRadius: 12px;

// weight
$extraBold: 700;
$bold: 600;
$medium: 500;
$reguler: 400;

// common font size
$firstFont: 42px;
$secondFont: 38px;
$thirdFont: 32px;
$fourthFont: 24px;
$fifthFont: 16px;
$seventhFont: 14px;
$sixthFont: 12px;