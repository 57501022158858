@import "./variable";
@import "./mixins";

// Global
.heading {
  @include column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 16px !important;
  @media (min-width: 768px) {
    margin-bottom: 24px !important;
  }
  label {
    font-size: 16px !important;
    color: #818181;
    display: none;
    @media (min-width: 768px) {
    display: block;
    }
  }
}
.seeAll {
  border-radius: 8px;
  border: 2px solid $second;
  color: $second;
  font-weight: 600;
  font-size: 12px;
  width: fit-content;
  padding: 6px 16px;
  text-align: center;
  @media (min-width: 768px) {
    padding: 12px 36px;
    width: 300px;
    font-size: $fifthFont;
    &:hover {
      transition: all 0.3s ease-in-out;
      background-color: $second;
      box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
        rgba(17, 17, 26, 0.05) 0px 8px 32px;
      color: $white;
    }
  }
}

.mobileHero {
  padding: 6px 4px 8px;
  margin-top: 0px;
  margin-bottom: 32px;
  @media (min-width: 768px) {
    margin-top: 52px;
    margin-bottom: 62px;
  }
  @media(min-width: 1024px) {
    margin-bottom: 124px;
  }
  cursor: pointer;
  &__top {
    display: flex;
    gap: 0.75rem;
    @media (min-width: 768px) {
      gap: 1rem;
    }
    margin-bottom: 16px;
    &__left {
      flex: 4;
      background-color: #ffdfe0;
      border-radius: 8px;
      position: relative;
      height: 150px;
      @media (min-width: 768px) {
        height: 280px;
        border-radius: 16px;
      }
    }
    &__right {
      flex: 3;
      background-color: #f0dfff;
      position: relative;
      border-radius: 8px;
      height: 150px;
      @media (min-width: 768px) {
        height: 280px;
        border-radius: 16px;
      }
    }
  }
  &__bottom {
    // display: flex;
    // gap: 16px;
    &_item {
      flex: 1;
      background-color: #d7fdd7;
      border-radius: 8px;
      position: relative;
      height: 80px;
      @media (min-width: 768px) {
        height: 180px;
        border-radius: 16px;
      }
    }
  }
}

.mobileHero__bottom_icon {
  position: absolute;
  bottom: 0;
  right: 0;
  img {
    width: 46px;
    @media (min-width: 768px) {
      width: 120px;
    }
    border-radius: 8px;
  }
}

.mobileHero_title {
  font-weight: 600;
  font-size: 13px;
  padding: 10px 12px !important;
  @media (min-width: 768px) {
    font-size: 21px;
    padding: 20px !important;
  }
  @media (min-width: 1024px) {
    font-size: 22px;
  }
  line-height: normal;
}
.mobileHero_icon {
  position: absolute;
  bottom: 0px;
  right: 0;
  img {
    width: 100px;
    @media (min-width: 768px) {
      width: 220px;
    }
    border-radius: 8px;
  }
}
.content {
  // margin-top: -24px;
  padding-top: 8px;
  z-index: 1;
  position: relative;
  background-color: $white;
  border-radius: 12px 12px 0px 0px;
  @media (min-width: 768px) {
    margin-top: 0px;
    padding-top: 0px;
  }
}

.heroShot {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  position: relative;
  display: flex;
  height: 275px;
  align-items: flex-end;
  padding-bottom: 24px;
  z-index: 0;
  @media (min-width: 768px) {
    height: 65vh;
    align-items: center;
    padding-bottom: 0px;
  }
  .overlay {
    opacity: 0.8;
    background: $primary;
    mix-blend-mode: multiply;
    height: 100%;
    position: absolute;
    width: 100%;
    margin-bottom: -24px;
    @media (min-width: 768px) {
      margin-bottom: 0px;
    }
  }
  .wrapText {
    position: relative;
    z-index: 9;
    // max-width: 713px;
    margin: auto;
    text-align: left;
    @media (min-width: 768px) {
      text-align: center;
    }
    .mainHeader {
      font-weight: 700;
      color: $white;
      font-size: $fourthFont !important;
      // width: 75%;
      margin-bottom: 7px;
      @media (min-width: 768px) {
        margin-bottom: 9px;
        font-weight: 600;
        font-size: $firstFont !important;
        width: 100%;
      }
    }
    .subHeader {
      color: $white;
      font-weight: 400 !important;
      font-size: $sixthFont;
      line-height: 24px;
      margin-top: 10px !important;
      margin-bottom: 23px !important;
      @media (min-width: 768px) {
        line-height: normal;
        font-size: $fourthFont;
        margin-top: 21px !important;
        margin-bottom: 32px !important;
        width: 100%;
      }
    }
  }
}

.shortcut {
  // margin-top: 32px;
  // @media (min-width: 768px) {
  //   margin-top: 72px;
  // }
  @include column;
  h1 {
    margin-bottom: 16px !important;
    @media (min-width: 768px) {
      margin-bottom: 24px !important;
    }
  }
  .shortcutWrap {
    justify-content: space-between;
    // gap: 24px;
    &__desktop {
      display: none;
    }
    &__mobile {
      display: flex;
      padding: 0 16px;
      @media (min-width: 425px) {
        padding: 0px;
      }
    }
    @media (min-width: 768px) {
      &__desktop {
        display: flex;
      }
      &__mobile {
        display: none;
      }
    }
    .iconWrap {
      @include column;
      gap: 4px;
      @media (min-width: 768px) {
        gap: 6px;
      }
      text-decoration: none !important;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      align-items: center;
      padding: 12px 8px;
      margin-bottom: 16px !important;
      .textCategory {
        color: #000;
        text-align: center;
        font-weight: 500;
        font-size: 9px;
        width: 54px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (min-width: 600px) {
          font-size: 12px;
          width: fit-content;
        }
        @media (min-width: 768px) {
          font-size: $fifthFont;
          padding: 18px 8px;
        }
      }
      // width: calc(16.66666667% - 24px);
      img {
        width: 32px;
        height: 32px;
        @media (min-width: 768px) {
          width: 67px;
          height: 67px;
        }
      }
    }
  }
}

.popular {
  order: 2;
  // margin-top: 24px;
  // gap: 24px;

  @media (min-width: 768px) {
    // margin-top: 97px;
    gap: 24px;
    order: 1;
  }
  display: flex;
  align-items: center;
  flex-direction: column;
  .item1 {
    grid-area: item1;
  }
  .item2 {
    grid-area: item2;
  }
  .item3 {
    grid-area: item3;
  }
  .item4 {
    grid-area: item4;
  }
  .item5 {
    grid-area: item5;
  }
  .item1 {
    h3 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 2rem;
      font-weight: 600;
    }
  }

  .item2,
  .item3,
  .item4,
  .item5 {
    h3 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      font-size: 1.4rem;
      font-weight: 600;
    }
  }

  .gridWrap {
    display: grid;
    gap: 16px;
    width: 100%;
    height: 500px;
    grid-template-columns: 50% 25%;
    grid-template-areas:
      "item1 item2 item3"
      "item1 item4 item5";

    p {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    a {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      color: $white;
      padding: 28px;
      gap: 6px;
      border-radius: $mainRadius;
      background-image: linear-gradient(rgba(255, 255, 255, 0) 30%, #000 100%);
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        z-index: -1;
        margin: -28px;
        border-radius: $mainRadius;
      }
    }
  }
}

.banner {
  @include column;
  padding: 32px 24px;
  margin-top: 24px;
  order: 1;
  margin-bottom: 32px;
  @media (min-width: 768px) {
    @include row;
    padding: 60px 68px;
    margin-top: 105px;
    margin-bottom: 101px;
    order: 2;
  }
  justify-content: space-between;
  background-color: $second;
  border-radius: 24px;
  .detailWrap {
    @include column;
    align-items: flex-start;
    @media (min-width: 768px) {
      @include row;
      align-items: center;
    }
    gap: 27px;
    img {
      border-radius: 50%;
      background-color: #ecf7ee;
      width: 64px;
      height: 64px;
      padding: 16px;
      @media (min-width: 768px) {
        padding: 23px;
        width: 93px;
        height: 93px;
      }
    }
    .detail {
      @include column;
      gap: 8px;
      h2 {
        color: #fff;
        font-weight: 700;
        font-size: 18px;
        @media (min-width: 768px) {
          font-size: 24px;
        }
      }
      .text {
        color: #fff !important;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 24px;
        @media (min-width: 768px) {
          line-height: 32px;
          font-size: 20px;
          margin-bottom: 0px;
        }
      }
    }
  }
  .bannerBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      background-color: #fff !important;
      padding: 16px 28px;
      border-radius: 8px;
      font-weight: 700;
      font-size: 14px;
    }
  }
}

.recomendation {
  @include col_item_center;
  margin-top: 24px;
  @media (min-width: 768px) {
    margin-top: 38px;
  }
  &:last-child {
    margin-bottom: 102px;
  }
  // gap: 60px;
  .recWarp {
    // justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
    @media (min-width: 768px) {
      margin-bottom: 2rem;
    }
    .recCard {
      @include column;
      height: auto;
      margin-bottom: 24px;
    }
  }
}

// Small Layout

@media (max-width: 767.98px) {
}

// Medium
@media (max-width: 991.98px) {
  .popular {
    .gridWrap {
      height: fit-content;
      grid-template-columns: inherit;
      grid-template-areas:
        "item1 item1"
        "item2 item3"
        "item4 item5";
      .item1 {
        height: 300px;
      }
      div {
        height: 200px;
        padding: 16px;
        img {
          margin: -16px;
        }
      }
    }
  }

  // .whyUs {
  //   @include col_item_center;
  //   .whyUsWarp {
  //     .whyUsCard {
  //       @include col_item_center;
  //       margin-bottom: 32px;
  //     }
  //   }
  // }
}

.banner {
  // height: 400px;
  .bannerWrap {
    // align-items: center;
    // img {
    //   position: inherit;
    //   width: auto;
    //   top: 0px;
    //   margin-bottom: 16px;
    // }
    // .bannerDetail {
    //   margin-bottom: 24px;
    //   @include column;
    //   align-items: center;
    //   text-align: center;
    // }
    .bannerBtn {
      display: flex;
      align-items: center;
      a {
        background-color: #fff !important;
      }
      // justify-content: center;
    }
  }
}

.popularWrap {
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0px;
  @media (min-width: 768px) {
    margin-bottom: 8rem;
  }
}

.titleHome {
  text-align: left;
  @media (min-width: 768px) {
    text-align: center;
  }
}

.tabCategory {
  display: flex;
  justify-content: start;
  gap: 0.5rem;
  margin-top: 16px;
  margin-bottom: 28px;
  @media (min-width: 768px) {
    justify-content: center;
    gap: 1rem;
    margin-top: 24px;
    margin-bottom: 56px;
  }
  .tab {
    font-size: 12px;
    border-radius: 6px;
    background-color: #efefef;
    font-weight: 400;
    padding: 6px 18px;
    display: flex;
    align-items: center;
    color: #969696;
    cursor: pointer;
    @media (min-width: 768px) {
      font-size: 16px;
    }
    &__active {
      font-weight: 600;
      color: $white;
      background-color: $second;
    }
  }
}
