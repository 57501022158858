.boxAuth {
  width: 100%;
  min-height: 100vh;
  margin: 0px;
  .background {
    position: relative;
    padding: 0px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .overlay {
      width: 100%;
      height: 100%;
      opacity: 0.8999999761581421;
      background-color: #00854c;
      mix-blend-mode: multiply;
      position: absolute;
      top: 0;
    }
  }
  .formBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 51px 16px;
    gap: 32px;
    .circle {
      display: none;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: inherit;
        height: inherit;
      }
      // background-color: #00854c;
    }
    .form {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 16px 24px;
      @media (min-width: 768px) {
        width: 451px;
        padding: 46px 50px;
      }
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      color: #000;
      .title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      .text {
        color: #000;
        font-size: 14px;
        font-weight: 400;
      }
      form {
        margin-top: 45px;
        margin-bottom: 24px;
        .inputGroup {
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin-bottom: 16px;
          .inputWrap {
            position: relative;
            img {
              position: absolute;
              top: 8px;
              left: 12px;
            }
            .password {
              position: absolute;
              top: 12px;
              right: 12px;
              left: unset;
            }
            .inputBox {
              border-radius: 8px;
              padding: 8px 14px;
              border: 1px solid #d9d9d9;
              width: 100%;
              height: 42px;
              &:focus-visible {
                outline: none;
              }
              &__password {
                padding: 8px 14px;
              }
            }
          }
        }
        .hook {
          color: #000;
          font-size: 14px;
          font-weight: 400;
          text-align: end;
          cursor: pointer;
        }
        button {
          width: 100%;
          text-align: center;
          padding: 12px 0px;
          border-radius: 6px;
          color: #fff;
          background-color: #fca418;
          border: none;
          margin-top: 52px;
          font-size: 14px;
          font-weight: 600;
        }
      }
      .sign {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        a {
          text-decoration: none !important;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .background {
    display: none;
  }
  .circle {
    display: flex !important;
  }
}
