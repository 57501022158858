@import "./mixins";
@import "./variable";

.profileDesktop {
  min-height: calc(100vh - 24px);
  // overflow-y: auto;
  .navigationLeft {
    position: sticky;
    top: 32px;
    margin-top: 124px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    padding: 22px 28px;

    .profileWrap {
      display: flex;
      margin-bottom: 42px;
      .profile {
        margin-right: 20px;
        .profileCircle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 63px;
          height: 63px;
          background-color: #d9d9d9;
          border-radius: 32px;
        }
      }
      .profileInformation {
        .name {
          color: $text;
          font-size: $fourthFont;
          font-weight: $extraBold;
          margin-bottom: 0px;
        }
        .subName {
          color: $text;
          font-size: $fifthFont;
          opacity: 0.6;
          font-weight: $reguler;
        }
      }
    }
    .navList {
      .navItem {
        cursor: pointer;
        border-radius: 6px;
        border: 1px solid $second;
        padding: 18px 22px;
        display: flex;
        align-items: center;
        font-size: $fifthFont;
        font-weight: $bold;
        margin-bottom: 16px;
        color: $text;
        text-decoration: none !important;
        &:last-child {
          // margin-bottom: 32px;
        }
        img {
          margin-right: 10px;
        }
        &__active {
          color: $white;
          background: $second;
        }
        &__logout {
          margin-top: 36px;
          justify-content: center;
          background-color: $third;
          color: $white;
        }
        a {
        }
      }
    }
  }

  .menuShow {
    margin-top: 24px;
    @media (min-width: 768px) {
      margin-top: 124px;
    }
    h1 {
      font-size: $fourthFont !important;
      // margin-bottom: 32px !important;
    }
    label {
      color: $text;
      margin-bottom: 12px;
      font-size: 14px;
      font-weight: $reguler;
    }
    input {
      margin-bottom: 8px !important;
      padding: 12px 14px;
      border: 1px solid #d9d9d9;
      width: 100%;
    }
    textarea {
      margin-bottom: 28px !important;
    }

    .imgIcon {
      position: absolute;
      top: 12px;
      left: 12px;
    }

    .accountBankText {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      @media (min-width: 991px) {
        flex-direction: row;
      }
      button {
        margin-bottom: 32px !important;
        background-color: transparent !important;
        color: $primary;
        border: none !important;
        font-size: $fifthFont;
        font-weight: $extraBold;
      }
    }
    .filterDataHistory {
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    }
    .filterHistory {
      display: flex;
      gap: 12px;
      @media (min-width: 768px) {
        gap: 0;
      }

      .historyMainStatus {
        color: $text;
        margin-right: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: $extraBold;
        display: none;
        margin-bottom: 42px;
        align-items: center;
        @media (min-width: 768px) {
          display: flex;
        }
      }
      .btnFilter {
        margin-bottom: 42px;
        cursor: pointer;
        color: $second;
        border-radius: 6px;
        border: 2px solid $second;
        box-shadow: 0px 4px 10px 2px rgba(228, 145, 15, 0.2);
        padding: 10px 16px;
        width: calc(33% - 6px);
        font-size: 12px;
        text-align: center;
        @media (min-width: 991px) {
          width: 100%;
          font-size: 14px;
          margin-right: 12px;
          padding: 10px 46px;
        }
        &__active {
          color: $white;
          font-weight: $extraBold;
          background: $second;
        }
      }
    }

    // Bank
    .bankList {
      .bankItem {
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #d9d9d9;
        padding: 16px 18px;
        margin-bottom: 16px;
        .bankCircle {
          width: 51px;
          height: 51px;
          border-radius: 32px;
          margin-right: 10px;
          background-color: #d9d9d9;
        }
        .bankInfo {
          flex: 2;
          color: $text;
          .bankName {
            font-size: 14px;
            font-weight: $bold;
            margin-bottom: 2px;
          }
          .bankNumber {
            font-size: 14px;
            font-weight: $reguler;
            opacity: 0.5;
          }
        }
        .bankAction {
          flex: 1;
          text-align: right;
        }
      }
    }
  }
}
.historyList {
  .historyItem {
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 20px;
    &:last-child {
      border-bottom: none;
    }
    .historyDate {
      display: flex;
      align-items: center;
      span {
        margin-right: 8px;
      }
      color: #212121;
      font-size: 12px;
      font-weight: $reguler;
    }
    .historyStatus {
      border-radius: 4px;
      padding: 2px 8px;
      background: $second;
      width: fit-content;
      color: $white;
      font-size: 8px;
      display: flex;
      align-items: center;

      @media (min-width: 768px) {
        font-size: 10px;
      }
      text-align: center;
      font-weight: $extraBold;
      // box-shadow: 0px 4px 10px 2px rgba(228, 145, 15, 0.2);
    }
  }
  .historyContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 8px;

    @media (min-width: 768px) {
      margin-bottom: 20px;
      flex-direction: row;
    }
    .historyLeft {
      margin-bottom: 12px;
      @include row();
      .historyImg {
        width: 81px;
        height: 81px;
        border-radius: 4px;
        background: #d9d9d9;
        margin-right: 20px;
        img {
          width: inherit;
          height: inherit;
          object-fit: cover;
          border-radius: 4px;
        }
      }

      .historyWrap {
        .historyName {
          color: #292d32;
          font-weight: $extraBold;
          margin-bottom: 8px;
          font-size: 14px;
          @media (min-width: 768px) {
            font-size: $fifthFont;
          }
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .historyPrice {
    color: #212121;
    font-size: $fifthFont;
    font-weight: $extraBold;
  }
  .historyRight {
    margin-bottom: 8px;

    @media (min-width: 768px) {
      margin-bottom: 20px;
    }
    .historyLabel {
      margin-bottom: 0px;
      color: #212121;
      font-weight: $reguler;
      opacity: 0.6;
      font-size: 10px;
      text-align: left;
      margin-top: 12px;
      @media (min-width: 768px) {
        text-align: right;
        margin-top: 0px;
        font-size: 12px;
      }
    }
  }
  .historyAction {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    @media (min-width: 768px) {
      justify-content: flex-end;
    }
    .historyDetail {
      button {
        color: $white;
        font-weight: $extraBold;
        background-color: transparent !important;
        color: $second;
        margin-right: 8px;
        border: 1px solid $second;
        font-size: 12px;
        padding: 8px 36px;
        @media (min-width: 768px) {
          padding: 12px 36px;
          font-size: 16px;
        }
      }
    }
    .historySee {
      button {
        width: 165px;
        border-radius: 6px;
        background: $second;
        padding: 8px 36px;
        color: $white;
        border: 1px solid $second;
        font-size: 12px;
        @media (min-width: 768px) {
          font-size: 16px;
          padding: 12px 36px;
        }
        font-weight: $extraBold;
      }
    }
  }
}
