@import "./../../styles/variable";

.modalContent {
    height: 70vh;
    overflow: auto;
    .result {
        cursor: pointer;
    }
    .result:hover {
        background-color: $primary;
        color: $white;
    }
}