@import "./mixins";
@import "./variable";

.ps-5 {
  padding-left: 2.5rem !important;
}

.splide__pagination__page {
  transform: scale(1) !important;
  height: 10px !important;
  width: 10px !important;
}
.splide__pagination__page.is-active {
  width: 28px !important;
  border-radius: 24px !important;
  transform: scale(1) !important;
  height: 10px !important;
}

._inside-accordion button {
  padding-left: 0px !important;
  padding-right: 0px !important;
  background-color: transparent !important;
}

._inside-accordion button:focus {
  box-shadow: none !important;
  border: none !important;
}

._kloter-empty {
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 16px;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

input,
textarea {
  border-radius: 6px;
  border: 1px solid #949698;
  padding: 8px 16px;
  font-size: 14px !important;
  @media (min-width: 768px) {
    padding: 12px 16px;
  }
}

table {
  thead {
    tr {
      th {
        font-size: 14px;
        @media (min-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 14px;
        @media (min-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
}

h1 {
  font-size: $fourthFont !important;
  font-weight: $extraBold !important;
  line-height: normal !important;
  @media (min-width: 768px) {
    font-weight: 600 !important;
    font-size: $firstFont !important;
  }
  margin: 0px !important;
}

h2 {
  font-size: $secondFont;
  font-weight: $extraBold !important;
  line-height: normal;
  margin: 0px !important;
}

h3 {
  font-size: $fourthFont;
  font-weight: $extraBold;
  line-height: normal;
  margin: 0px !important;
}

p {
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: $fifthFont;
  }
  font-weight: $reguler !important;
  line-height: normal;
  margin: 0px !important;
}

.text {
  font-size: $fourthFont;
  font-weight: 400 !important;
  line-height: 36px;
  color: #888;
}

.react-datepicker-wrapper {
  width: 100%;
  input {
    width: 100%;
  }
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  top: 3px !important;
}

a {
  text-decoration: none !important;
}

// Button
.btnYellow {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  width: fit-content;
  text-align: center;
  font-size: $sixthFont;
  padding: 12px 42px;
  font-weight: $bold;
  border-radius: 6px;
  background: $second;
  @media (min-width: 768px) {
    font-weight: 700;
    font-size: 16px;
    padding: 16px 42px;
  }
  &__outline {
    color: $second;
    border: 2px solid $second;
    background-color: $white;
  }
}

.menuSub {
  padding: 10px 24px;
  border-radius: 6px !important;
  border: 1px solid #fca418;
  background-color: #fff;
  color: #fca418;
  font-weight: 600;
  &_active {
    background-color: #fca418;
    color: #fff;
  }
}

.btnWrapProfile {
  margin-top: 16px;
  margin-bottom: 102px;
  @media (min-width: 768px) {
    margin-bottom: 32px;
  }
  button {
    color: $white;
    font-size: 14px;
    font-weight: $extraBold;
    padding: 10px 42px !important;
    border-radius: 6px !important;
    background: $second;
    width: 100%;
    @media (min-width: 768px) {
      width: fit-content;
    }
  }
}

.accordion-header {
  button {
    &.accordion-button {
      padding: 12px 16px;
      border-radius: 4px !important;
    }
  }
}

._custom {
  .accordion-item {
    border: none !important;
  }
}
.accordionWrapChildren {
  .accordion-item {
    border: none !important;
    .accordion-header {
      .accordion-button {
        // padding: 12px 0px !important;
        border: none !important;
      }
    }
  }
}

// Custom More
@media (max-width: 425px) {
  .col-3 {
    width: calc(25% - 8px) !important;
    padding: 0 !important;
  }
}

.splide__pagination {
  bottom: -1.75em !important;
  &__page {
    background: #5889f0 !important;
    &.active {
      background: $primary !important;
    }
  }
}

.splide__arrow--prev {
  left: 0rem !important;
}

.splide__arrow--next {
  right: 0rem !important;
}

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

html,
body {
  padding: 0;
  margin: 0 auto;
  height: 100%;
  overflow: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  font-family: "Rubik", sans-serif !important;
  box-sizing: border-box;
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 18px !important;
}

// h2 {
//   font-size: 28px;
// }

// h3 {
//   font-size: 24px;
// }

// h4 {
//   font-size: 20px;
// }

// h5 {
//   font-size: 16px;
// }

// h6 {
//   font-size: 14px;
// }
@media (min-width: 576px) {
  h1 {
    font-size: 30px !important;
  }

  h2 {
    font-size: 28px !important;
  }

  h3 {
    font-size: 24px !important;
  }

  h4 {
    font-size: 20px !important;
  }

  h5 {
    font-size: 16px !important;
  }

  h6 {
    font-size: 14px !important;
  }
}
@media (min-width: 768px) {
  h1 {
    font-size: 36px !important;
  }

  h2 {
    font-size: 30px !important;
  }

  h3 {
    font-size: 28px !important;
  }

  h4 {
    font-size: 24px !important;
  }

  h5 {
    font-size: 20px !important;
  }

  h6 {
    font-size: 16px !important;
  }
}

label {
  font-size: 12px !important;
  @media (min-width: 768px) {
    font-size: 14px !important;
  }
}

.accordion-header button {
  font-size: 14px;
  border: 1px solid #d8d8d8;
  border-radius: 8px !important;
  @media (min-width: 768px) {
    font-size: 16px;
  }
}

// input[type="text"],
// input[type="date"],
// input[type="number"],
// input[type="email"],

// input[type="password"],
input,
select {
  border-radius: 8px;
  border: none !important;
  background-color: #f3f3f3 !important;
  // height: 42px;
  // width: 100%;
  padding: 12px 14px;
  margin-bottom: 14px;
  font-size: 14px !important;
  @media (min-width: 768px) {
    margin-bottom: 24px;
    font-size: 16px !important;
    // height: 36px;
  }
}

textarea {
  background-color: #f3f3f3 !important;
}

input[type="checkbox"] {
  height: 18px;
}

textarea {
  border-radius: 8px;
  border: none;
  background-color: #f3f3f3;
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 14px;
  font-size: 14px !important;
  @media (min-width: 768px) {
    margin-bottom: 24px;
    font-size: 16px !important;
    padding: 12px 24px;
  }
}

.tamu-wrap {
  padding: 12px 16px;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  .tamu-title {
    font-size: 12px;
    font-weight: 600;
  }
  .tamu-counter {
    input {
      border: none;
      background-color: #e1e1e1;
      width: 32px;
      padding: 6px;
      text-align: center;
      height: 28px;
      &:last-child {
      }
    }
  }
}

input::placeholder,
textarea::placeholder,
select {
  color: #7d7d7d !important;
  font-weight: 400;
  line-height: 24px;
}

.small-label {
  font-size: 10px;
}

._btn-main {
  padding: 8px 24px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
}

._btn-main:hover {
  opacity: 0.8;
}

._btn-main.primary {
  border: 1px solid #5889f0;
  background-color: #5889f0;
  color: #fff;
}

._btn-main.outline {
  border: 2px solid #5889f0;
  background-color: #fff;
  color: #5889f0;
}

@media (min-width: 768px) {
  ._btn-main {
    padding: 8px 32px;
    font-size: 16px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 0px;
  height: 3px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: $primary;
  border: 2px solid #555555;
}
