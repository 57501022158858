@import "./variable";

.line {
  position: absolute;
}

.textPreview {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
}

.labelCustom {
  font-size: 10px;
  margin-bottom: 0;
  color: #909090;
  @media (min-width: 768px) {
    font-size: 12px;
  }
}

.itemItinerary {
  display: flex;
  gap: 16px;
  position: relative;
  width: 100%;
  .iconWrapItinerary {
    background: #f1f1f1;
    padding: 14px 8px;
    .icon {
      width: 30px;
      height: 30px;
      border-radius: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      &_bus {
        background: $primary;
      }
      &_train {
        background: $second;
      }
      &_flight {
        background: $third;
      }
      &_hotel {
        background-color: $fourth;
      }
    }
  }
  .dateRangeWrap {
    width: 100%;
    .dateRangeItinerary {
      display: flex;
      padding-top: 14px;
      .date {
        font-size: 10px;
        font-weight: 500;
        color: rgb(21, 21, 21);
      }
    }
    .typeAccomodation {
      margin-bottom: 12px;
      font-weight: 600;
    }
    .wrapDestination {
      display: flex;
      justify-content: space-between;
      .destination {
        width: 50%;
        font-size: 12px;
        font-weight: 600;
        .label {
        font-weight: 400;
          font-size: 10px;
          color: #909090;
        }
      }
    }
    .cardAgenda {
      padding: 12px 16px;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
      width: 100%;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
      .titleAgenda {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 12px;
        border-bottom: 1px solid rgb(197, 197, 197);
        padding-bottom: 6px;
      }
      .subTitle {
        font-size: 10px;
        color: #909090;
      }
      .dateText {
        font-size: 12px;
        margin-bottom: 12px;
      }
    }
  }
}
