.loadingWrap {
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(232, 232, 232, 0.5);
  z-index: 999;
  position: fixed;
  font-weight: 600;
  width: 100%;
  top: 0;
}
