@import "./variable";
@import "./mixins";

.btnAction {
  border: 2px solid $second;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 12px;
  border-radius: 6px;
  background-color: #fff;
  color: $second;
  width: 100%;
  @media (min-width: 768px) {
    font-size: 16px;
    padding: 10px 16px;
  }
  &_reset {
    border: none;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 12px;
    border-radius: 4px;
    background-color: $third;
    color: $white;
    margin-bottom: 8px;
  }
}

.text {
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  span {
    font-weight: 600;
    &.subtotalText {
      text-decoration: line-through;
      color: red;
    }
    &.discountText {
      padding-left: 2px;
      color: green;
    }
  }
}
.totalText {
  font-size: 14px;
  font-weight: 600;
}
.btnContinue {
  border: 1px solid $second;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 12px;
  border-radius: 6px;
  background-color: $second;
  color: $white;
  width: auto;
  margin-top: 0;
  @media (min-width: 768px) {
    font-size: 16px;
    padding: 10px 16px;
    // width: 100%;
    // margin-top: 12px;
  }
  &_outline {
    border: 1px solid $second;
    font-size: 12px;
    font-weight: 600;
    padding: 8px 12px;
    border-radius: 6px;
    background-color: transparent;
    color: $second;
    margin-top: 0;
    @media (min-width: 768px) {
      font-size: 16px;
      padding: 10px 16px;
      margin-top: 12px;
    }
  }
  // margin-top: 12px;
}

.titleCalculator {
  font-weight: 600;
}

.stickyAction {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  padding: 12px;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0px -6px 27px 0px rgba(0, 0, 0, 0.1);
  margin-top: 0;
  @media (min-width: 768px) {
    position: relative;
    padding: 0;
    box-shadow: none;
    margin-top: 24px;
  }
  .previewPrice {
    // display: block;
    // @media (min-width: 768px) {
    //   display: none;
    // }
  }
  .previewTotal {
    padding: 12px;
  }
}

.wrapIti {
  display: flex;
  border-radius: 8px;
  border: 1px solid rgb(204, 204, 204);
  margin-bottom: 12px;
  .orderIti {
    background: #f5f5f5;
    border-radius: 6px 0 0 6px;
    padding: 16px 6px;
    @media (min-width: 768px) {
      padding: 16px 12px;
    }
    .idxIti {
      font-weight: 600;
      font-size: 12px;
      width: 22px;
      height: 22px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      border: 1px solid rgb(204, 204, 204);
      color: rgb(204, 204, 204);
      margin-bottom: 8px;
    }
  }
  .formField {
    padding: 16px 12px 4px 12px;
    width: calc(100% - 2rem);
    @media (min-width: 768px) {
      padding: 24px 20px;
    }
  }
}
