@import "./variable";
@import "./mixins";

.heading {
  color: #000;
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 24px;
  }
  font-weight: 700;
}
.pilgrimWrap {
  margin-bottom: 16px;
  .pilgrimContainer {
    // padding: 12px;
    border-radius: 8px;
    // border: 1px solid #d9d9d9;
    .inputForm {
      .accordionWrapChildren {
        margin-top: 0;
        button {
          border: none;
          border-radius: 0 !important;
          // border-bottom: 1px solid #cacaca !important;
          // border-top: 1px solid #cacaca !important;
        }

        .accordion-item {
          border: none !important;
        }
      }
    }
  }
}
.contentWrap {
  padding: 50px 0 16px;
  @media (min-width: 768px) {
    padding: 80px 0px;
  }
  .formWrap {
    form {
      margin-top: 16px;

      @media (min-width: 768px) {
        margin-top: 33px;
      }
      .inputGroup {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 16px;
        .inputWrap {
          position: relative;
          img {
            position: absolute;
            top: 12px;
            left: 12px;
          }
          .password {
            position: absolute;
            top: 12px;
            right: 12px;
            left: unset;
          }
          .inputBox {
            border-radius: 8px;
            padding: 12px 14px 12px 46px;
            border: 1px solid #d9d9d9;
            width: 100%;
            &:focus-visible {
              outline: none;
            }
            &__password {
              padding: 12px 46px;
            }
            &_confirmation {
              padding: 12px 14px;
            }
          }
        }
      }

      .terms {
        @include row;
        align-items: center;
        flex-wrap: nowrap;
        font-size: 14px;
        font-weight: $reguler;
        gap: 10px;
        margin-top: 16px;
        opacity: 0.5;
        input {
          padding: 0px;
          width: 24px;
          height: 24px;
          background-color: $textFade;
        }
      }
    }
    .paymentMethodDekstop {
      @include column;
      gap: 16px;
      margin: 52px 0px;
      .headingWrap {
        @include row;
        justify-content: space-between;
        align-items: center;
        .btnAdd {
          padding: 8px 25px;
          background-color: $primary;
          border-radius: 8px;
          color: $white;
          font-size: 14px;
          font-weight: $bold;
        }
      }
      .inputGroupRadio {
        @include row;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        @media (min-width: 768px) {
          padding: 24px 16px;
        }
        border-radius: 8px;
        border: 1px solid #d9d9d9;
        .inputWrap {
          @include row;
          gap: 10px;
          align-items: center;
          .circle {
            width: 51px;
            height: 51px;
            background-color: gray;
            border-radius: 50%;
          }
          .bankWrap {
            .name {
              font-size: 16px;
              font-weight: $bold;
            }
            .noRek {
              font-size: 14px;
              font-weight: $reguler;
              opacity: 0.5;
            }
          }
        }
        .inputBank {
          input {
            padding: 0px;
          }
        }
      }
    }
    .paymentMethodMobile {
      display: none;
    }
    .btn {
      width: 100%;
      margin: 32px 0px 24px;

      @media (min-width: 768px) {
        margin: 66px 0px;
      }
    }
  }

  .summaryPrice {
    .summaryBox {
      @include column;
      padding: 32px;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      color: #292d32;
      font-size: 16px;
      font-weight: 500;
      position: sticky;
      top: 6.5rem;
      .title {
        color: #292d32;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 15px;
      }
      .textWrap {
        @include row;
        justify-content: space-between;
        margin-bottom: 12px;
      }
      .iconBox {
        @include row;
        gap: 4px;
        color: $textFadeTwo;
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 4px;
        img {
          width: 18px;
          width: 18px;
        }
      }
      .totalWrap {
        @include row;
        justify-content: space-between;
        margin-top: 50px;
        padding-top: 27px;
        border-top: 1px solid #d9d9d9;
        .heading {
          color: #292d32;
          font-size: 16px;
          font-weight: 500;
        }
        .total {
          color: $primary;
          font-size: 16px;
          font-weight: 700;
        }
      }
      .btn {
        margin-top: 56px;
        background-color: $primary;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .contentWrap {
    .formWrap {
      form {
        margin-top: 16px;
        @media (min-width: 768px) {
          margin-top: 33px;
        }
        .paymentMethodDekstop {
          display: none;
        }
        hr {
          margin: 35px 0px;
          display: block !important;
        }
        .paymentMethodMobile {
          @include column;
          gap: 24px;
          padding: 26px 19px;
          border-radius: 8px;
          border: 1px solid #d9d9d9;
          .heading {
            font-size: 18px;
            font-weight: 700;
          }
          .inputGroupRadio {
            @include row;
            justify-content: space-between;
            align-items: center;
            .inputWrap {
              @include row;
              gap: 10px;
              align-items: center;
              .circle {
                width: 51px;
                height: 51px;
                background-color: gray;
                border-radius: 50%;
              }
              .bankWrap {
                .name {
                  font-size: 16px;
                  font-weight: $bold;
                }
                .noRek {
                  font-size: 14px;
                  font-weight: $reguler;
                }
              }
            }
            .inputBank {
              input {
                padding: 0px;
              }
            }
          }
          .btnAdd {
            padding: 14px 0px;
            background-color: $primary;
            border-radius: 8px;
            color: $white;
            font-size: 14px;
            font-weight: $bold;
            text-align: center;
          }
        }
      }
    }

    .summaryPrice {
      display: none;
    }
  }
}
