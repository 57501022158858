@import "./../../styles/variable";

.headerWrap {
  display: none;
  @media (min-width: 768px) {
    display: block;
    position: sticky;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    top: 0;
    z-index: 10;
    min-width: 100%;
    background-color: #fff;
    .logo {
      width: 52px;
      height: 52px;
      border-radius: $mainRadius;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .headerMenu {
      a {
        color: $text !important;
        font-size: $fifthFont;
        font-weight: 600;
      }
      &__dark {
        a {
          color: #000 !important;
        }
      }
    }
  }
  .headerSearch {
    position: relative;
    flex: 2;
    margin-right: 8px;
    img {
      position: absolute;
      left: 14px;
      top: 17px;
    }
    input {
      width: 100%;
      border-radius: 8px;
      border: none;
      box-shadow: none;
      background-color: #f3f3f3;
    }
    &__dark {
      input {
        // border: 1px solid #464646;
      }
    }
  }
  .headerUser {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: #fff;
    border-radius: 50%;
  }
}

.headerMobile {
  display: flex;
  padding: 16px;
  position: sticky;
  background-color: #fff;
  z-index: 9;
  width: 100%;
  top: 0;
  .headerSearch {
    position: relative;
    flex: 2;
    margin-right: 16px;
    img {
      position: absolute;
      left: 14px;
      top: 12px;
    }
    input {
      width: 100%;
      border: none;
    }
  }
  .headerCart {
    display: flex;
    align-items: center;
  }
  @media (min-width: 768px) {
    display: none;
  }
}

// Footer
.footer {
  background-color: #ecf7ee;
  padding: 16px 0px;
  .footerWrap {
    padding-top: 35px;
    padding-bottom: 30px;
    min-height: 330px;
    .profile {
      display: flex;
      flex-direction: row;
      gap: 25px;
      align-items: center;
      margin-bottom: 32px;
      img {
        background-color: #fff;
        width: 52px;
        border-radius: 32px;
      }
      .detail {
        .name {
          color: #000;
          font-size: 24px;
          font-weight: 600;
        }
        .summary {
          color: #000;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
    .iconBox {
      display: flex;
      flex-direction: row;
      gap: 21px;
      align-items: center;
      margin-bottom: 22px;
      .detail {
        color: #000;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .menuFooter {
      display: flex;
      flex-direction: column;
      gap: 19px;
      margin-bottom: 22px;
      .heading {
        color: #000;
        font-size: 24px;
        font-weight: 700;
      }
      a {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        text-decoration: none;
      }
    }
  }
  .copyright {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    background-color: #ecf7ee;
  }
}

.stickyNav {
  display: block;
  &__checkout {
    display: flex;
    gap: 16px;
  }
  position: fixed;
  bottom: 0;
  background-color: $white;
  height: 70px;
  width: 100%;
  padding: 14px 16px;
  z-index: 9;
  box-shadow: 0px -6px 27px 0px rgba(0, 0, 0, 0.1);
  @media (min-width: 768px) {
    display: none;
  }
  .totalSection {
    float: left;

    .text {
      color: #292d32;
      font-size: 12px;
      font-weight: 500;
    }
    .price {
      color: #212121;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
    }
  }
  .checkoutBtn {
    padding: 10px 24px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    background: #00854c;
    float: right;
  }
  .button {
    text-align: center;
    width: 50%;
    padding: 10px 24px !important;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;

    &__addCart {
      float: left;
      border: 1px solid #fca418;
      color: #292d32;
    }
    &__buyPackages {
      color: #fff;
      float: right;
      background-color: #fca418;
    }
  }
  .menuMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    font-size: 11px;
    color: #000;
    img {
      margin-bottom: 2px;
    }
    &_center {
    }
  }
}

.custom {
  background-color: $second;
  position: absolute;
  top: -1.75rem;
  border-radius: 56px;
  height: 52px;
  width: 52px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  padding: 6px;
  justify-content: center;
  .menuMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    font-size: 11px;
    color: #fff;
    img {
      height: 30px;
      width: 30px;
    }
  }
}

.btnLogin {
  background-color: #fca418;
  padding: 10px 24px;
  border: none;
  color: #fff;
  font-weight: 600;
  button {
    font-size: 12px;
  }
}

.wrapProfile {
  position: relative;
  .hoverableMenu {
    position: absolute;
    display: none;
  }
  &:hover {
    .hoverableMenu {
      display: block;
      position: absolute;
      padding-top: 24px;
      right: 0;
      div {
        border-radius: 10px;
        background: white;
        display: flex;
        flex-direction: column;
        padding: 12px 20px;
        a {
          padding: 12px 0;
          width: 100px;
          color: #000;
          border-bottom: 1px solid #cacaca;
          &:last-child {
            border: none;
          }
          // font-size: 14px;
          // padding
        }
      }
    }
  }
}
